import React from 'react';
import Img from 'gatsby-image';

function Post({ post }) {
  const { title, author_image, author_name, date, tags } =
    post.node.frontmatter;
  const authorImgFluid = author_image.childImageSharp.fluid;
  const { excerpt, slug } = post.node;

  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <div className="flex space-x-2">
            {tags.map((tag) => (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-purple-tb-400 text-gray-200">
                {tag}
              </span>
            ))}
          </div>
          <a href={`/blog/${slug}`} className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">{title}</p>
            <p className="mt-3 text-base text-gray-500">{excerpt}</p>
          </a>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0">
            <span className="sr-only">{author_name}</span>
            <Img
              fluid={authorImgFluid}
              className="h-10 w-10 rounded-full"
              alt=""
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">{author_name}</p>
            <div className="flex space-x-1 text-sm text-gray-500">
              <time dateTime={date}>{date}</time>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Blog({ posts }) {
  return (
    <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            From the blog
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Learn about finance automation and tax compliance
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <Post post={post} key={post.slug} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blog;
